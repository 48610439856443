import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ValidationMixin from "@/core/plugins/validation-mixin";
import { PATCH } from "@/core/services/store/request.module";
export default {
  name: "password-update",
  mixins: [ValidationMixin],
  data: function data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      formValid: true,
      formLoading: false,
      formData: {
        engineer: null,
        password: null,
        password_confirmation: null
      }
    };
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this = this;

      if (!_this.$refs.passwordForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.engineer = _this.$route.params.id;
      var url = "engineer/" + _this.engineer + "/password";

      _this.$store.dispatch(PATCH, {
        url: url,
        data: _this.formData
      }).then(function () {
        _this.formValid = true;
        _this.formData = {
          password: null,
          password_confirmation: null
        };

        _this.$emit("password:updated");
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    },
    mounted: function mounted() {
      var _this = this;

      _this.formData.engineer = this.$route.params.id;
    },
    created: function created() {
      var _this = this;

      _this.engineer = _this.$route.params.id;
    }
  }
};